<template>
  <div>
    <FehlerMeldung :error="error" />
    <div v-if="ready">
      <!--Falls ein Fehler auftritt => wird angezeigt-->
      <span v-if="error">
        <v-card
          class="mx-auto my-12"
          max-width="800"
        >
          <v-card-title>
            Bewertung löschen
          </v-card-title>
          <v-card-text>
            Bewertung konnte nicht gelöscht werden!
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="$router.push({ path: `/thema/${id}/zusammenfassung` })"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
              Zurück
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </span>
    </div>
    <v-card-text v-else>
      <div class="text-center progress-circular-container">
        <v-progress-circular
          size="50"
          color="primary"
          indeterminate
        />
      </div>
    </v-card-text>
  </div>
</template>

<script>
import FehlerMeldung from '@/components/Meldungen/FehlerMeldung.vue'
import api from '@/utils/api'

export default {
  components: {
    FehlerMeldung
  },
  props: {
    id: {
      required: true,
      default: null
    }
  },

  data: () => ({
    error: null,
    ready: false
  }),

  mounted () {
    this.$store.commit("title", "Bewertung Löschen")
    const url = `/api/thema/${this.$route.params.id}/bewertung/`
    api.del(url).then(() => {
      this.ready = true;
      this.$router.push({ path: `/themen` })
    }).catch(error=> {
      this.error = error
      this.ready = true;
    })
  }
}
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>