<template>
  <div>
    <!-- Fehlermeldung -->
    <FehlerMeldung :error="error" />
    <v-card
      class="mx-auto justify-sm-space-between"
      max-width="800"
    >
      <v-container
        class="pa-2"
        fluid
      >
        <div>
          <!-- Überschrift / Lösch-Button -->
          <v-card-title>
            <v-container>
              <v-row>

                <v-spacer />
                <v-btn
                  depressed
                  elevation="1"
                  color="error"
                  :disabled="bewertung == null"
                  style="margin:auto"
                  @click="dialogVisible = true"
                >
                <DialogBestaetigen
                  :visible="dialogVisible"
                  titel="Bewertung"
                  :frage="delfrage"
                  @bestaetigen="deleteBewertung"
                  @close="dialogVisible = false"
                />
                Bewertung Löschen
                  <v-icon large>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-card-title>
          <v-col>
            <!-- Übersicht Tabelle -->
            <v-card>
              <v-card-title>
                Übersicht
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-if="punkte"
                  v-model="name"
                  readonly
                  label="Thema"
                />
                <v-text-field
                  v-if="punkte"
                  v-model="gewichtung"
                  readonly
                  label="Gewichtung"
                  type="number"
                />
                <v-simple-table
                  v-if="punkte"
                >
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Mitarbeiter
                        </th>
                        <th class="text-left">
                          Punkte
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="punkte">
                      <tr
                        v-for="(pu, index) in punkte"
                        :key="index"
                      >
                        <td>
                          {{ pu.name }}
                        </td>
                        <td>
                          {{ pu.punkte }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div
                  v-else
                  class="text-center progress-circular-container"
                >
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-card-title>
            Fragen
          </v-card-title>
          <div v-if="ready">
            <!-- Übersicht Fragen: 1 v-card pro Frage -->
            <v-col
              v-for="(item, index) in items"
              :key="index"
              cols="12"
            >
              <v-card>
                <v-card-title>
                  Frage {{ index + 1 }}:
                </v-card-title>
                <v-card-text>
                  <div
                    class="subtitle-1 mb-2"
                    style="color:black"
                  >
                    {{ item.frage }}
                  </div>
                  <v-divider />
                  <v-list>
                    <v-subheader>Punkte</v-subheader>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-icon>
                          {{ item.punkte[0].punkte }}
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.punkte[0].name" />
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          {{ item.punkte[1].punkte }}
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.punkte[1].name" />
                        </v-list-item-content>
                      </v-list-item><v-list-item>
                        <v-list-item-icon>
                          {{ item.punkte[2].punkte }}
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.punkte[2].name" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </div>
          <v-card-text
            v-else
            class="text-center progress-circular-container"
          >
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
          </v-card-text>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import api from "@/utils/api";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import DialogBestaetigen from "@/components/Dialoge/DialogBestaetigen.vue"
export default {
  components: {
    FehlerMeldung,
    DialogBestaetigen
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data: () => ({
    bewertung: null,
    name: "",
    items: [],
    thema: { fragen: [] },
    error: null,
    punkte: null,
    gewichtung: 0,
    ready: false,
    dialogVisible: false,
    delfrage: "Wollen sie die Bewertung wirklich löschen?"
  }),

  mounted() {
    api.get(`/api/thema/${this.id}/`).then(response => {
      if (response && response.themen && response.themen.length > 0) {
        this.thema = response.themen[0];
        this.$store.commit("title", `Ergebnisse: ${this.thema.thema}`);
      }
      api
        .get(`/api/thema/${this.thema._id}/bewertung`)
        .then(response2 => {
          if (
            response2 &&
            response2.bewertungen &&
            response2.bewertungen.length > 0
          ) {
            this.gewichtung = response2.bewertung.thema.gewichtung;
            this.name = response2.bewertung.thema.name;
            this.items = response2.bewertungen;
            this.bewertung = response2.bewertung;
          }
          const punkte = []
          if(response2.bewertungen.length > 0){
            response2.bewertung.bewertung.forEach(item => {
              item.punkte.forEach(pu=> {
                if(punkte[pu.id] == null){
                  punkte[pu.id] = []
                  punkte[pu.id].name = pu.name
                  punkte[pu.id].punkte = 0
                  punkte[pu.id].punkte = punkte[pu.id].punkte + pu.punkte
                }else{
                  punkte[pu.id].punkte = punkte[pu.id].punkte + pu.punkte
                }
              })
            });
          }
          const punkte2 = []
          punkte.forEach(punkte=> {
            if(punkte != null){
              punkte2.push(punkte)
            }
          })
          this.punkte = punkte2
          punkte2.sort((a, b) => a.punkte < b.punkte? 1 : -1)
          this.ready = true;
        })
        .catch(error => {
          this.ready = true;
          this.error = error;
        });
    }).catch(error=> {
      this.error = error;
    });
  },
  methods: {
    deleteBewertung(){
      if(this.bewertung != null){
        this.$router.push({ path: `/thema/${this.bewertung._id}/bewertung/delete` });
      }
    }
  }
};
</script>
