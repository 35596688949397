<template>
  <div>
    <FehlerMeldung
      v-if="error"
      :error="error"
    />
    <v-card
      class="mx-auto my-12"
      max-width="800"
    >
      <v-card-title>
        <v-container>
          <v-row>
            Übersicht Backups   
            <div class="flex-grow-1" />
            <v-btn
              outlined
              color="primary"
              style="margin:auto"
              :loading="isloading"
              :disabled="!ready"
              @click="backupErstellen()"
            >
              Backup erstellen 
              <v-icon
                right
              >
                mdi-cloud-upload-outline
              </v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text>
        <div v-if="ready">
          <v-autocomplete
            v-model="BackupsDatum"
            :items="BackupsDatume"
            dense
            filled
            label="Datum"
            clearable
          />
          <v-simple-table
            v-if="backups.length!=0"
            class="row-pointer"
          >
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Datum
                  </th>
                  <th class="text-left">
                    Uhrzeit
                  </th>

                  <th class="text-left">
                  Anzahl Themen
                  </th>
                  <th>
                    Anzahl freigegebene Themen
                  </th>
                </tr>
              </thead>
              <tbody v-if="backupsgefiltert">
                <tr
                  v-for="(backup, index) in backupsgefiltert"
                  :key="index"
                  @click="opendetail(backups[index])"
                >
                  <td>
                    {{ getDateFromString(backup.createdAt) }}
                  </td>
                  <td>
                    {{ getUhrzeitFromString(backup.createdAt) }}
                  </td>
                  <td>
                  {{ backup.themen.length }}
                  </td>
                  <td>
                    {{ countFreigegebeneThemen(backup) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span v-else>
            Noch keine Backups erstellt!
          </span>
        </div>
        <div
          v-else
          class="text-center progress-circular-container"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "@/utils/api";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";

export default {
  components: {
    FehlerMeldung
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data: () => ({
    backupsgefiltert: [],
    backups: [],
    BackupsDatume: [],
    BackupsDatum: null,
    error: null,
    ready: false,
    isloading: false
  }),
  watch:{
    BackupsDatum(){
      this.setBackupsGefiltet();
    }
  },
  mounted() {
    this.$store.commit("title", "Backups");
    this.backupsVonApiHolen();

  },
  methods: {
    opendetail(data) {
      this.$router.push({ path: `/backups/${data._id}` });
    },
    getDateFromString(data){
      return data.substring(0, 10) 
    },
    getUhrzeitFromString(data){
      // Stunden werden in der Datenbank mit Coordinated Universal Time gespeichert, deshalb addiere ich 2h dazu
      return ((Number(data.substring(11, 13)) + 2) % 24) + data.substring(13, 16)
    },
    backupErstellen(){
      const url = '/api/backup';
      this.isloading = true;
      api.post(url).then(() =>{
        this.isloading = false; 
        this.backupsVonApiHolen();
      }).catch(error =>{
        this.error = error;
        this.isloading = false;
      });
    },
    backupsVonApiHolen(){
      const url = '/api/backup';
      this.ready = false;
      api
        .get(url)
        .then(response => {
          this.backups = response.backups;
          this.setBackupsGefiltet()
          this.backups.forEach(backup => {
            this.BackupsDatume.push(this.getDateFromString(backup.createdAt))
          });
          this.ready = true;
        })
        .catch(error => {
          this.error = error;
          this.ready = true;
        });
    },
    countFreigegebeneThemen(backup){
      const themen = backup.themen.filter(thema => thema.freigegeben == true)
      return themen.length
    },
    setBackupsGefiltet(){
      if(this.BackupsDatum == null){
        this.backupsgefiltert = this.backups
      }else{
        this.backupsgefiltert = this.backups.filter(backup => this.getDateFromString(backup.createdAt) == this.BackupsDatum)
      }
    }
  }
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
  .row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
