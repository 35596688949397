<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="clipped"
      enable-resize-watcher
      app
      width="auto"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Systent Gmbh
          </v-list-item-title>
          <v-list-item-subtitle id="Benutzer">
            {{ getBenutzer }}
          </v-list-item-subtitle>
          <v-list-item-subtitle id="kindOfUser">
            {{ getKindOfUser }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider 
        v-if=" $store.getters.isSuperUser || $store.getters.isAdmin"
      />
      <v-list dense>
        <v-list-item
          v-if="$store.getters.isthemenzeitraum"
          :to="{ name: 'themen' }"
        >
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Start</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="(navItem, index) in navItems"
          :key="index"
          :to="navItem.route"
        >
          <v-list-item-action>
            <v-icon>{{ navItem.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ navItem.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Jahresabschluss -->
        <v-list-item
          v-if="$store.getters.ispunktevergabe"
          :to="{ name: 'abschlussbewertung' }"
        >
          <v-list-item-action>
            <v-icon>mdi-bullseye-arrow</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Jahresabschluss</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Zwischenstand -->
        <v-list-item :to="{ name: 'uebersichtpunkte' }">
          <v-list-item-action>
            <v-icon>mdi-format-list-bulleted-square </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Zwischenstand</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Themen/Fragen -->
        <v-list-item :to="{ name: 'themenfragenuebersicht' }">
          <v-list-item-action>
            <v-icon>mdi-format-list-text </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Themen/Fragen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Historie Bewertungen -->
        <v-list-item :to="{ name: 'historybewertungen' }">
          <v-list-item-action>
            <v-icon>mdi-history </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Historie Bewertungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Trennlinie -->
        <v-divider
          v-if="$store.getters.isSuperUser || $store.getters.isAdmin || $store.getters.isVerwaltung"
          class="divider"
        />

        <!-- Backups verwalten -->
        <v-list-item
          v-if="$store.getters.isSuperUser || $store.getters.isAdmin || $store.getters.isVerwaltung"
          :to="{ name: 'backups' }"
        >
          <v-list-item-action>
            <v-icon>mdi-backup-restore</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Backups verwalten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Themen verwalten -->
        <v-list-item
          v-if="$store.getters.isSuperUser || $store.getters.isAdmin || $store.getters.isVerwaltung"
          :to="{ name: 'themenbearbeiten' }"
        >
          <v-list-item-action>
            <v-icon>mdi-briefcase-edit-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Themen verwalten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Themen Zeiträume -->
        <v-list-item
          v-if="$store.getters.isSuperUser || $store.getters.isAdmin|| $store.getters.isVerwaltung"
          :to="{ name: 'themenzeitraumbearbeiten' }"
        >
          <v-list-item-action>
            <v-icon>mdi-calendar-clock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Themen Zeiträume</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Themen Bewertungen -->
        <v-list-item
          v-if="$store.getters.isSuperUser || $store.getters.isAdmin"
          :to="{ name: 'bewertungenuebersicht' }"
        >
          <v-list-item-action>
            <v-icon>mdi-playlist-check</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Themen Bewertungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Jahresabschluss Zeitraum -->
        <v-list-item
          v-if="$store.getters.isSuperUser || $store.getters.isAdmin"
          :to="{ name: 'zeitraumbearbeiten' }"
        >
          <v-list-item-action>
            <v-icon>mdi-calendar-clock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Jahreabschluss Zeitraum</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Auswertung Jahresabschluss -->
        <v-list-item
          v-if="$store.getters.isSuperUser || $store.getters.isAdmin"
          :to="{ name: 'abschlussbewertungauswertung' }"
        >
          <v-list-item-action>
            <v-icon>mdi-human-male-board-poll</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Auswertung Jahresabschluss</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Mitarbeiter Refresh -->
        <v-list-item
          v-if="$store.getters.isSuperUser || $store.getters.isAdmin"
          @click="mitarbeiterRefresh"
        >
          <v-list-item-action>
            <v-icon>mdi-cloud-refresh</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mitarbeiter Refresh</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <DialogMitarbeiterRefresh
          :visible="dialogVisible"
          :refresh-mitarbeiter="refreshMitarbeiter"
          @close="dialogVisible = false"
        />
        <!-- Blacklist bearbeiten -->
        <v-list-item
          v-if="$store.getters.isSuperUser || $store.getters.isAdmin"
          :to="{ name: 'blacklistbearbeiten' }"
        >
          <v-list-item-action>
            <v-icon>mdi-account-cancel</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Blacklist bearbeiten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Admin verwalten -->
        <v-list-item
          v-if="$store.getters.isSuperUser"
          :to="{ name: 'adminsverwalten' }"
        >
          <v-list-item-action>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Admin verwalten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Verwaltung verwalten -->
        <v-list-item
          v-if="$store.getters.isSuperUser || $store.getters.isAdmin"
          :to="{ name: 'verwaltung' }"
        >
          <v-list-item-action>
            <v-icon>mdi-account-edit</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Verwaltung verwalten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Trennlinie -->
        <v-divider
          class="divider"
        />
        <!-- Logout -->
        <v-list-item :to="{ name: 'login' }">
          <v-list-item-action>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      app
      color="indigo"
      dark
    >
      <v-btn
        icon
        @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $store.getters.title }} </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col>
            <router-view :key="$route.path" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer
      color="indigo"
      app
    >
      <span class="white--text">&copy; 2022</span>
    </v-footer>

    <v-snackbar
      v-model="snackbar.visible"
      :color="snackbar.color"
      outlined
    >
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbar.visible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import api from "@/utils/api";
import DialogMitarbeiterRefresh from "../components/Dialoge/DialogMitarbeiterRefresh.vue";

export default {
  name: "BaseAppLayout",
  components: {
    DialogMitarbeiterRefresh
  },
  data: function() {
    return {
      drawer: true,
      clipped: false,
      snackbar: {
        visible: false,
        text: "",
        color:""
      },
      dialogVisible: false,
      refreshMitarbeiter: false
    };
  },

  computed: {
    navItems() {
      return this.$store.getters.navigationItems;
    },
    getBenutzer() {
      if(localStorage.getItem("user_first_name")
        &&localStorage.getItem("user_last_name")){
        return (
          localStorage.getItem("user_first_name") +
          " " +
          localStorage.getItem("user_last_name")
        );
      }else
        return ""
    },
    getKindOfUser(){
      let ret = "Mitarbeiter"
      if(this.$store.getters.isSuperUser){
        ret = "SuperUser"
      }else if(this.$store.getters.isAdmin){
        ret = "Admin"
      }else if(this.$store.getters.isVerwaltung){
        ret = "Verwaltung"
      }
      return ret
    }
  },

  async created() {
    let requiresAuth = true;
    if (
      "requiresAuth" in this.$route.meta &&
      this.$route.meta.requiresAuth === false
    ) {
      requiresAuth = false;
    }
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (requiresAuth && !isAuthenticated) {
      localStorage.removeItem("token");
      localStorage.removeItem("isAuthenticated");
      this.$router.push({ path: "/login" });
    }
  },

  mounted() {
    api
      .get("/api/info/rechtegruppe")
      .then(response => {
        this.$store.commit("isVerwaltung", response.isverwaltung);
        this.$store.commit("isAdmin", response.isadmin);
        this.$store.commit("isSuperUser", response.issuperuser);
        this.$store.commit("isthemenzeitraum", response.isthemenzeitraum);
        this.$store.commit("ispunktevergabe", response.ispunktevergabe);
        this.$store.commit(
          "ispunktevergabevorbei",
          response.ispunktevergabevorbei
        );
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit("isVerwaltung", false);
        this.$store.commit("isAdmin", false);
        this.$store.commit("isSuperUser", false);
      });
  },
  methods: {
    async mitarbeiterRefresh() {
      this.dialogVisible = true
      this.refreshMitarbeiter = !this.refreshMitarbeiter
    }
  }
};
</script>
<style>
.divider{
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
