<template>
  <VerwaltungListVerwalten />
</template>

<script>
import VerwaltungListVerwalten from "../../components/Verwalten/VerwaltungListVerwalten.vue";
export default {
  components: {
    VerwaltungListVerwalten
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data: () => ({}),
};
</script>
