<template>
  <ThemenFragenUebersicht
    :ready="ready"
    :themen="themen"
    :error="error"
    :show-freigegeben="showFreigegeben"
  />
</template>

<script>
import api from "@/utils/api";
import ThemenFragenUebersicht from '../../components/Themen/ThemenFragenUebersicht.vue'

export default {
  components: {
    ThemenFragenUebersicht
  },

  data: () => ({
    error: null,
    ready: false,
    themen: [],
    showFreigegeben: false
  }),
  computed: {},
  mounted() {
    this.$store.commit("title", "Themen/Fragen Übersicht");
    const url = `/api/themen/freigegeben`;
    api
      .get(url)
      .then(response => {
        this.themen = response.themen
        this.ready = true;
      })
      .catch(error => {
        this.error = error;
        this.ready = true;
      });
  }
};
</script>
 