import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: 'Themen',
    abstimmung: {},
    punkte: [],
    navigationItems: [],
    isVerwaltung: false,
    isAdmin: false,
    isSuperUser: false,
    isthemenzeitraum: false,
    ispunktevergabe: false,
    ispunktevergabevorbei: false
  },
  mutations: {
    ispunktevergabevorbei(state, ispunktevergabevorbei){
      state.ispunktevergabevorbei = ispunktevergabevorbei
    },
    ispunktevergabe(state, ispunktevergabe){
      state.ispunktevergabe = ispunktevergabe
    },
    isthemenzeitraum(state, isthemenzeitraum){
      state.isthemenzeitraum = isthemenzeitraum
    },
    isVerwaltung (state, isVerwaltung){
      state.isVerwaltung = isVerwaltung
    },
    isSuperUser (state, isSuperUser){
      state.isSuperUser = isSuperUser
    },
    isAdmin (state, isAdmin){
      state.isAdmin = isAdmin
    },
    title (state, title) {
      state.title = title
    },
    abstimmung (state, { thema, abstimmung }) {
      Vue.set(state.abstimmung, thema, abstimmung)
    },
    clearAbstimmung (state, { thema }) {
      Vue.set(state.abstimmung, thema, {})
    },
    punkte (state, { person }) {
      // gibt es bereits, punkte addieren
      const found = state.punkte.filter(item => item.id === person.id)
      if (found.length === 1) {
        const eintrag = found[0]
        const neuePunkte = person.punkte + eintrag.punkte
        Vue.set(found[0], 'punkte', neuePunkte)
      } else {
        // Vue.set(state, 'punkte', [person, ...this.state.punkte])
        state.punkte.push(person)
      }
    },
    clearRechte:(state)=> {
      state.isVerwaltung = false
      state.isAdmin = false
      state.isSuperUser = false
    },
    clearPunkte: (state) => () => {
      state.punkte = []
    },
    clearNavigationItems: (state) => () => {
      Vue.set(state, 'navigationItems', [])
    },
    addNavigationItem (state, { item }) {
      state.navigationItems.push(item)
    }
  },
  getters: {
    ispunktevergabevorbei: state => state.ispunktevergabevorbei,
    ispunktevergabe: state => state.ispunktevergabe,
    isthemenzeitraum: state => state.isthemenzeitraum,
    isVerwaltung: state => state.isVerwaltung,
    isSuperUser: state => state.isSuperUser,
    isAdmin: state => state.isAdmin,
    title: state => state.title,
    abstimmung: (state) => (thema) => {
      return state.abstimmung[thema]
    },
    punkte: (state) => () => {
      const sortable = []
      state.punkte.forEach(item => {
        sortable.push([item.punkte, item.name, item.id])
      })
      // for (var punkte in state.punkte) {
      //   sortable.push([punkte.punkte, state.punkte[punkte].name])
      // }

      sortable.sort(function (a, b) {
        return a[1] - b[1]
      })
      return sortable
    },
    navigationItems: state => state.navigationItems
  },
  actions: {
    clearAbstimmung (context, { thema }) {
      context.commit('clearAbstimmung', { thema })
    },
    clearPunkte (context) {
      context.commit('clearPunkte')
    },
    clearNavigationItems (context) {
      context.commit('clearNavigationItems')
    },
    addNavigationItem (context, { item }) {
      context.commit('addNavigationItem', { item })
    }
  }
})
