<template>
  <ThemaBearbeiten
    v-if="id != null"
    :id="id"
  />
</template>

<script>
import ThemaBearbeiten from "../../components/Themen/ThemaBearbeiten.vue";
export default {
  components: {
    ThemaBearbeiten
  },
  props: {
    id: {
      type: Number,
      required: true,
      default: Number
    }
  },
  mounted() {
    this.$store.commit("title", "Thema bearbeiten");
  }
};
</script>
