<template>
  <AdminVerwalten />
</template>

<script>
import AdminVerwalten from "../../components/Verwalten/AdminVerwalten.vue";
export default {
  components: {
    AdminVerwalten
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data: () => ({}),
  mounted() {
    this.$store.commit("title", "Adminliste bearbeiten");
  }
};
</script>
