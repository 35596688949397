<template>
  <div>
    <FehlerMeldung :error="error" />
    <WarnungMeldung
      :message="warnung.text"
      :visible="getwarnungvisible"
    />
    <v-card
      class="mx-auto my-12"
      max-width="574"
    >
      <v-card-title>
        Auswertung {{ getYear }} - ({{ abstimmung }} Stimmen)
      </v-card-title>
      <v-card-text v-if="auswertung">
        <JsonExcel
          v-if="error == null && ready"
          :data="getData()"
          :style="{ cursor: 'pointer' }"
        >
          <v-icon>
            mdi-download
          </v-icon>
          Excel Download
        </JsonExcel>
        <div v-if="ready">
          <v-simple-table ref="auswertungTable">
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Punkte
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in auswertung"
                  :key="item[0].name"
                >
                  <td>{{ item[0].name }}</td>
                  <td>{{ item[0].punkte }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-card-text v-else>
          <div class="text-center progress-circular-container">
            <v-progress-circular
              size="50"
              color="primary"
              indeterminate
            />
          </div>
        </v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="auswertung.length == 0 || !fehlendeReady"
          color="error"
          @click="openDialog()"
        >
          <v-icon>
            mdi-delete
          </v-icon>
          Bewertungen löschen
        </v-btn>
        <DialogBestaetigen
          :visible="dialogVisible"
          titel="Alle Jahresbewertungen löschen"
          frage="Alle Jahresbewertungen wirklich löschen?"
          @bestaetigen="deleteBewertungen"
          @close="dialogVisible = false"
        />
      </v-card-actions>
    </v-card>
    <v-card
      v-if="($store.getters.ispunktevergabe || $store.getters.ispunktevergabevorbei)"
      class="mx-auto my-12"
      max-width="574"
    >
      <div v-if="fehlendeReady">
        <span v-if="nichtabgestimmt && nichtabgestimmt.length != 0">
          <v-card-title>
            Es fehlen :
          </v-card-title>
          <v-card-text>
            <v-simple-table ref="auswertungTable">
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in nichtabgestimmt"
                    :key="item.name"
                  >
                    <td>{{ item.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </span>
        <span v-else>
          <v-card-title>
            Alle haben abgestimmt!
          </v-card-title>
        </span>
      </div>
      <v-card-text v-else>
        <div class="text-center progress-circular-container">
          <v-progress-circular
            size="50"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "@/utils/api";
import JsonExcel from "vue-json-excel";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import DialogBestaetigen from "@/components/Dialoge/DialogBestaetigen.vue";
import WarnungMeldung from "@/components/Meldungen/WarnungMeldung.vue"
export default {
  components: {
    DialogBestaetigen,
    JsonExcel,
    FehlerMeldung,
    WarnungMeldung
  },
  props: {},

  data: () => ({
    auswertung: [],
    abstimmung: "--/--",
    error: null,
    nichtabgestimmt: null,
    ready: false,
    fehlendeReady: false,
    dialogVisible: false,
    warnung:{
      text: "Auswertung ist nur möglich bei Aktiver Punktevergabe oder bei beendeter Punktevergabe!"
    }
  }),
  computed: {
    getwarnungvisible(){
      if(this.$store.getters.ispunktevergabe || this.$store.getters.ispunktevergabevorbei)
        return false
      else
        return true
    },
    getYear(){
      const date = new Date();
      return date.getFullYear()
    }
  },
  mounted(){
    this.$store.commit("title", "Auswertung Jahresabschluss");
    this.DataFromApi();
  },

  methods: {
    getData() {
      const auswertung = [];
      if (this.auswertung) {
        this.auswertung.forEach(element => {
          const i = element[0];
          auswertung.push({ Punkte: i.punkte, Name: i.name });
        });
      }
      return auswertung;
    },
    openDialog() {
      this.dialogVisible = true;
    },
    deleteBewertungen() {
      this.dialogVisible = false;
      const URL = "/api/punkte";
      api
        .del(URL)
        .then(() => {
          this.DataFromApi();
        })
        .catch(error => {
          this.error = error;
        });
    },
    DataFromApi() {
      const url = `/api/punkte`;
      //Punkte
      api
        .get(url)
        .then(response => {
          this.auswertung = response.mitarbeiter;
          const statusUrl = `/api/abschluss/habenabgestimmt`;
          //haben abgestimmt
          api
            .get(statusUrl)
            .then(response => {
              this.abstimmung = response.habenabgestimmt;
              this.ready = true;
              //nicht abgestimmt
              api
                .get("/api/abschluss/nichtabgestimmt")
                .then(response => {
                  this.nichtabgestimmt = response.mitarbeiter;
                  this.fehlendeReady = true;
                })
                //nicht abgestimmt
                .catch(error => {
                  this.error = error;
                  this.fehlendeReady = true;
                });
            })
            //haben abgestimmt
            .catch(error => {
              this.error = error;
              this.ready = true;
            });
          //Punkte
        })
        .catch(error => {
          this.error = error;
          this.ready = true;
        });
    }
  }
};
</script>
 <style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>