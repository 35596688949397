<template>
  <BewertungsWizard
    :id="id"
  />
</template>

<script>
import BewertungsWizard from '@/components/Themen/BewertungsWizard.vue'

export default {
  components: {
    BewertungsWizard
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data: () => ({})
}
</script>
