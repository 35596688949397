<template>
  <!--übergibt das Aktuelle Datum in der Form jjjj-mm-dd-->
  <ZeitraumBearbeiten
    v-if="date"
    :current-date="date"
  />
</template>

<script>
import ZeitraumBearbeiten from "@/components/Zeitraume/ZeitraumBearbeiten.vue";

export default {
  components: {
    ZeitraumBearbeiten
  },
  data: () => ({
    date: null
  }),
  mounted() {
    this.$store.commit("title", "Jahreabschluss Zeitraum bearbeiten");
    const current = new Date();
    let date = `${current.getFullYear()}`;
    if (current.getMonth() + 1 < 10)
      date = date + `-0${current.getMonth() + 1}`;
    else date = date + `-${current.getMonth() + 1}`;
    if (current.getDate() < 10) date = date + `-0${current.getDate()}`;
    else date = date + `-${current.getDate()}`;
    this.date = date;
  }
};
</script>
