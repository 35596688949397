<template>
  <div>
    <v-card-actions
      v-if="ready"
    >
      <!-- Zurück zu Übersicht -->
      <v-btn
        text
        outlined
        color="primary"
        :to="{ name: 'backups' }"
      >
        <v-icon large>
          mdi-arrow-left
        </v-icon>
        Zurück zur Übersicht
      </v-btn>
      <v-spacer />

      <!-- Zurück -->
      <v-btn
        :loading="!button_ready"
        :disabled="!button_aktiv"
        outlined
        text
        color="primary"
        :to="{ name: 'backupzeitraum', params: { id: getNext() } }"
      >
        <v-icon large>
          mdi-arrow-left
        </v-icon>
        Zurück
      </v-btn>

      <!-- Weiter -->
      <v-btn
        :loading="!button_ready"
        :disabled="!button_aktiv"
        text
        outlined
        color="primary"
        :to="{ name: 'backupzeitraum', params: { id: getBevor() } }"
      >
        Weiter
        <v-icon 
          large 
          right
        >
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-card-actions>

    <ThemenFragenUebersicht
      :ready="ready"
      :themen="themen"
      :error="error"
      :show-freigegeben="showFreigegeben"
    />
  </div>
</template>

<script>
import api from "@/utils/api";
import ThemenFragenUebersicht from '@/components/Themen/ThemenFragenUebersicht.vue'

export default {
  components: {
    ThemenFragenUebersicht
  },

  data: () => ({
    backup_ids: [],
    error: null,
    ready: false,
    button_ready: false,
    button_aktiv: true,
    themen: [],
    showFreigegeben: true
  }),
  computed: {},
  mounted() {
    const id = this.$route.params.id;
    //const zeitraum = this.$route.params.zeitraum;
    const url = `/api/backup/${id}`;
    api
      .get(url)
      .then(response => {
        this.themen = response.backup[0].themen;
        const zeitraum = this.getDateFromString(response.backup[0].createdAt);
        this.$store.commit("title", "Backup vom " + zeitraum);

        this.ready = true;
        const url = '/api/backup';
        api
          .get(url)
          .then(response => {
            const backup_ids = [];
            response.backups.forEach(element => {
              backup_ids.push(element._id)
            });
            this.button_ready = true;
            this.backup_ids = backup_ids;
          })
          .catch(error => {
            this.button_ready = true;
            this.button_aktiv = false
            this.error = error;
            this.ready = true;
          });
      })
      .catch(error => {
        this.button_ready = true;
        this.button_aktiv = false
        this.error = error;
        this.ready = true;
      });

  },
  methods:{
    getDateFromString(data){
      // Stunden werden in der Datenbank mit Coordinated Universal Time gespeichert, deshalb addiere ich 2h dazu
      return data.substring(0, 10) + " " + ((Number(data.substring(11, 13)) + 2) % 24) + data.substring(13, 16)
    },
    getNext(){
      if(this.backup_ids.length != 0){
        const index = this.backup_ids.indexOf(this.$route.params.id);
        if(index == -1){
          return -1
        }else{
          if(index == this.backup_ids.length - 1){
            return this.backup_ids[0]
          }else{
            return this.backup_ids[index + 1]
          }
        }
      }else{
        return -1
      }
    },
    getBevor(){
      if(this.backup_ids.length != 0){
        const index = this.backup_ids.indexOf(this.$route.params.id);
        if(index == -1){
          return -1
        }else{
          if(index == 0){
            return this.backup_ids[this.backup_ids.length - 1]
          }else{
            return this.backup_ids[index - 1]
          }
        }
      }else{
        return -1
      }
    }
  }
};
</script>
 