<template>
  <Jahresabschlussbewertung />
</template>

<script>
import Jahresabschlussbewertung from '@/components/JahresAbschluss/Jahresabschlussbewertung.vue'

export default {
  components: {
    Jahresabschlussbewertung
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data: () => ({}),

}
</script>
