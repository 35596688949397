<template>
  <div>
    <!-- Fehlermeldung -->
    <FehlerMeldung
      v-if="error"
      :error="error"
    />

    <!-- Filter -->
    <v-card
      class="mx-auto my-12"
      max-width="800"
    >
      <v-card-title>
        Filter  
      </v-card-title>
      <v-card-text>
        <div v-if="ready">
          <!-- Filter Jahre -->
          <v-container fluid>
            <v-select
              v-model="selectedJahr"
              :items="jahre"
              label="Jahr"
              clearable
            />
          </v-container>

          <!-- Filter Zeitraum -->
          <v-container fluid>
            <v-select
              v-model="selectedZeitraume"
              :items="zeitraumeMöglich"
              label="Zeiträume"
              multiple
              :item-text="item => item.name"
              item-value="id"
            >
              <template #selection="{ item }">
                <v-chip>
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <template #prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggleZeitraume"
                >
                  <v-list-item-action>
                    <v-icon :color="selectedZeitraume.length > 0 ? 'indigo darken-4' : ''">
                      {{ iconZeitraume }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Alle Auswählen
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2" />
              </template>
            </v-select>
          </v-container>

          <!-- Filter Themen -->
          <v-container fluid>
            <v-select
              v-model="selectedThemen"
              :items="themen"
              label="Themen"
              multiple
              :item-text="item => item.name"
              item-value="id"
            >
              <template #selection="{ item }">
                <v-chip>
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
              <template #prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggleThemen"
                >
                  <v-list-item-action>
                    <v-icon :color="selectedThemen.length > 0 ? 'indigo darken-4' : ''">
                      {{ iconThemen }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      Alle Auswählen
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2" />
              </template>
            </v-select>
          </v-container>
        </div>
        <!-- Laden -->
        <div
          v-else
          class="text-center progress-circular-container"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>
    </v-card>

    <!-- Tabelle -->
    <v-card
      class="mx-auto my-12"
      max-width="800"
    >
      <v-card-title>
        Dein Persönlicher Zwischenstand:
      </v-card-title>
      <v-card-text>
        <div v-if="ready">
          <v-simple-table
            v-if="daten.length!=0"
            class="row-pointer"
          >
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Mitarbeiter
                  </th>
                  <th class="text-left">
                    Punkte
                  </th>
                </tr>
              </thead>
              <tbody v-if="daten">
                <tr
                  v-for="(da, index) in daten"
                  :key="index"
                >
                  <td>
                    {{ da.name }}
                  </td>
                  <td>
                    {{ da.punkte }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span v-else>
            Keine Bewertungen gefunden!
          </span>
        </div>
        <div
          v-else
          class="text-center progress-circular-container"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>
    </v-card>

    <!-- Diagramm -->
    <v-card
      class="mx-auto my-12"
      max-width="800"
    >
      <v-card-title>
        <span v-if="gesamtansicht">
          Punkte jeden Themenzeitraum
        </span>
        <span v-else>
          Gesamtpunkte
        </span>
        <v-spacer />
        <v-btn
          v-if="diagrammready"
          @click="ansichtwechslen"
        >
          Ansicht wechseln
        </v-btn>
      </v-card-title>
      <v-card-text v-if="diagrammready">
        <punkte-uebersicht-diagramm
          v-if="chartdata && options"
          :chartdata="chartdata"
          :options="options"
        />
      </v-card-text>
      <v-card-text v-else>
        <div class="text-center progress-circular-container">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "@/utils/api";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import PunkteUebersichtDiagramm from "@/components/Diagramme/DiagrammLine.vue";

export default {
  components: {
    FehlerMeldung,
    PunkteUebersichtDiagramm
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data: () => ({
    jahre: [],
    selectedJahr: null,
    
    zeitraume: [],
    zeitraumeMöglich: [],
    selectedZeitraume: [],

    themen: [],
    selectedThemen: [],
    
    blacklist:[],
    bewertungen: [],
    error: null,
    data_user: [],
    daten: [],
    ready: false,
    diagrammready: false,
    chartdata: null,
    chartdata1: null,
    chartdata2: null,
    gesamtansicht: false,
    options: {
      legend: {
        position: 'bottom'
      },
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0
        }
      },

    },
    themenColors: {
      1: '#385F73',
      2: '#1F7087',
      3: '#42A5F5',
      4: '#EF5350',
      5: '#952175',
      6: '#26A69A',
      7: '#AED581',
      8: '#665191',
      9: '#a05195',
      10: '#d45087',
      11: '#f95d6a',
      12: '#ffa600'
    }
  }),

  computed: {
    selectAllThemen () {
      return this.themen.length === this.selectedThemen.length
    },
    selectSomeThemen () {
      return this.selectedThemen.length > 0 && !this.selectAllThemen
    },
    iconThemen () {
      if (this.selectAllThemen) return 'mdi-close-box'
      if (this.selectSomeThemen) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    selectAllZeitraume () {
      return this.zeitraume.length === this.selectedZeitraume.length
    },
    selectSomeZeitraume () {
      return this.selectedZeitraume.length > 0 && !this.selectAllZeitraume
    },
    iconZeitraume () {
      if (this.selectAllZeitraume) return 'mdi-close-box'
      if (this.selectSomeZeitraume) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  watch: {
    selectedJahr(){
      this.calculateZeitraumeMöglich();
      this.calculateChartData();
      this.calculateTableData();
    },
    selectedZeitraume(){
      this.calculateChartData();
      this.calculateTableData();
    },
    selectedThemen(){
      this.calculateChartData();
      this.calculateTableData();
    }
  },

  mounted() {
    this.$store.commit("title", "Persönlicher Zwischenstand");

    const url2 = `/api/blacklist/`;
    this.loading = true;
    api
      .get(url2)
      .then(response => {
        this.blacklist = response.blacklist;

        const url2 = `/api/bewertungen/uebersicht`;
        api
          .get(url2)
          .then(response => {
            this.bewertungen = response.bewertungen;
            //Berechne die Zeitraum und Themen
            this.getZeitraumeAndThemenBewertungen();
            //Berechne die Daten, die für den Graphen gebracht werden
            this.calculateChartData();
            // Berechne die Daten, die für die Tabelle benotig werden
            this.getTableData();
            this.ready = true;

          })
          .catch(error => {
            this.ready = true;
            this.error = error;
            this.diagrammready = true;
          });
      })
      .catch(error => {
        this.error = error
        this.diagrammready = true
      })
  },
  methods: {
    opendetail(data) {
      this.$router.push({ path: `/bewertungen/${data.zeitraum.createdAt}` });
    },

    /*  
      Wechselt die Datensätze des Diagramms
    */
    ansichtwechslen(){
      if(this.gesamtansicht){
        this.chartdata = this.chartdata2
      }else{
        this.chartdata = this.chartdata1
      }
      this.gesamtansicht=!this.gesamtansicht
    },

    /*
      Berechnet die Themen und Zeitraume aus denn Bewertungen 
    */
    getZeitraumeAndThemenBewertungen(){
      const themenIds = [];
      const themenZeitraumeIds = [];
      const themen = [];
      const zeitraume = [];
      const jahre =  [];
      for(let i = 0; i < this.bewertungen.length; i++){
        const bew = this.bewertungen[i];
        if(!themenIds.includes(bew.thema_id)){
          themenIds.push(bew.thema_id);
          themen.push({
            name: bew.thema.name,
            id: bew.thema_id
          });
        }else{
          const index = themenIds.indexOf(bew.thema_id);
          themen[index] = {
            name: bew.thema.name,
            id: bew.thema_id
          }  
        }
        if(!themenZeitraumeIds.includes(bew.themenzeitraum._id)){
          themenZeitraumeIds.push(bew.themenzeitraum._id);
          zeitraume.push({
            name: bew.themenzeitraum.beginn + " - " + bew.themenzeitraum.ende,
            id: bew.themenzeitraum._id
          });
        }
        const date = new Date(bew.bewertungsdatum);
        const jahr = date.getFullYear();
        if(!jahre.includes(jahr)){
          jahre.push(jahr);
        }

      }
      this.themen = themen;
      this.zeitraume = zeitraume;
      if(jahre.includes(new Date().getFullYear())){
        this.selectedJahr = new Date().getFullYear();
      }
      this.jahre = jahre;
    },

    /* 
      Berechnet aus den Bewertungen ein array aus den Punkten/Personen, damit es später leichter
      ist die Daten der Tabelle zu aktualisieren
     */ 
    getTableData(){
      const data_user = [];
      this.bewertungen.forEach(bewertung => {
        for(let i = 0; i < bewertung.bewertung.length; i++){
          const frage = bewertung.bewertung[i];
          for(let j = 0; j < frage.punkte.length; j++){
            const punkte = frage.punkte[j];
            data_user.push({
              punkte: punkte.punkte,
              mitarbeiter_id: punkte.id,
              mitarbeiter_name: punkte.name,
              thema_id: bewertung.thema_id,
              zeitraum_id: bewertung.themenzeitraum._id,
              bewertungsdatum: bewertung.bewertungsdatum
            });
          }
        }
      });
      this.data_user = data_user;
      this.calculateTableData();
    },

    /*
      Selected entwerder alle Zeitraume oder keinen (Für den Button "Alles Auswählen") 
    */
    toggleZeitraume () {
      this.$nextTick(() => {
        if (this.selectAllZeitraume) {
          this.selectedZeitraume = []
        } else {
          const ids = [];
          this.zeitraume.forEach(zeitraum => ids.push(zeitraum.id));
          this.selectedZeitraume = ids;
        }
      })
    },

    /*
      Selected entwerder alle Themen oder keines (Für den Button "Alles Auswählen") 
    */
    toggleThemen () {
      this.$nextTick(() => {
        if (this.selectAllThemen) {
          this.selectedThemen = []
        } else {
          const ids = [];
          this.themen.forEach(thema => ids.push(thema.id));
          this.selectedThemen = ids;
        }
      })
    },

    /*
      Berechnet die Daten für das Diagramm, nimmt die Filter in Bezug bei der Berechnung
    */
    calculateChartData(){
      
      //Daten zu den Mitarbeitern
      const Mitarbeiter = []

      //Liste der ganzen Zeitraume
      const tz = []

      //Liste der Labels
      const label = []

      this.bewertungen.forEach(bewertung => {
        if((this.selectedJahr == null || this.selectedJahr == new Date(bewertung.bewertungsdatum).getFullYear()) &&
          (this.selectedThemen.length == 0 || this.selectedThemen.includes(bewertung.thema_id)) &&
            (this.selectedZeitraume.length == 0 || this.selectedZeitraume.includes(bewertung.themenzeitraum._id))){

          //Wenn es noch keine Bewertung mit diesem Zeitraum gab wird der Zeitraum/ Label hinzugefügt
          const found = tz.find(element => element == bewertung.themenzeitraum.createdAt);
          if(found == undefined){
            label.push(["Von: "+ bewertung.themenzeitraum.beginn, "Bis: "+ bewertung.themenzeitraum.beginn])
            tz.push(bewertung.themenzeitraum.createdAt)
          }

          const punkte = []
          //Zählt die Punkte aus der Bewertung aus
          bewertung.bewertung.forEach(item => {
            item.punkte.forEach(pu=> {
              if(punkte[pu.id] == null){
                punkte[pu.id] = []
                punkte[pu.id].name = pu.name
                punkte[pu.id].punkte = 0
                punkte[pu.id].punkte = punkte[pu.id].punkte + pu.punkte
              }else{
                punkte[pu.id].punkte = punkte[pu.id].punkte + pu.punkte
              }
            })
          });
          const punkte2 = []
          punkte.forEach(punkte=> {
            if(punkte != null){
              punkte2.push(punkte)
            }
          })
          //Die Punkte werden zu den Daten der Mitarbeiter hinzugefügt
          punkte2.forEach(pk=> {
            //schaut ob es den Mitarbeiter schon gibt
            const found = Mitarbeiter.find(element => element.label == pk.name);
            const foundblacklist = this.blacklist.find(element => element.name == pk.name);

            //Sucht die Indexe der Mitarbeiter und Zeitraum von der Bewertung
            const indexofMitarbeiter = Mitarbeiter.findIndex(element => element.label == pk.name)
            const indexofTz = tz.findIndex(element => element == bewertung.themenzeitraum.createdAt)
            // eslint-disable-next-line no-empty
            if(foundblacklist){}
            //Wenn es den Mitarbeiter noch nicht gibt wird er hinzugefügt
            else if(found == undefined ){
              Mitarbeiter.push({
                label: pk.name,
                //12 ist die Anzahl der Farben die es zur Auswahl gibt
                backgroundColor: this.themenColors[(Mitarbeiter.length % 12) + 1],
                borderColor: this.themenColors[(Mitarbeiter.length % 12) + 1],
                pointBackgroundColor: this.themenColors[(Mitarbeiter.length % 12) + 1],
                pointBorderColor: this.themenColors[(Mitarbeiter.length % 12) + 1],
                pointHoverBackgroundColor:this.themenColors[(Mitarbeiter.length % 12) + 1],
                pointHoverBorderColor:this.themenColors[(Mitarbeiter.length % 12) + 1],
                fill:false,
                data:[],
                hidden: false
              })
              Mitarbeiter[Mitarbeiter.findIndex(element => element.label == pk.name)].data[indexofTz]=pk.punkte

            //Wenn es den Mitarbeiter schon gibt werden seine Punkte nur erhört bzw gespeichten
            }else{
              //Wenn es noch keine Punkte für diesen Zeitraum gibt
              if(Mitarbeiter[indexofMitarbeiter].data[indexofTz] == null){
                Mitarbeiter[indexofMitarbeiter].data[indexofTz] = pk.punkte

                //Wenn es schon Punkte in diesem Zeitraum gibt werden sie nur erhöht
              }else{
                Mitarbeiter[indexofMitarbeiter].data[indexofTz] = Mitarbeiter[indexofMitarbeiter].data[indexofTz] + pk.punkte
              }
            }
          })
        }
      })
      //Wenn ein Mitarbeiter in einem Zeitraum keine Punkte erhalten hat werden sie auf 0 gesetzt
      tz.forEach((tz,index) => {
        Mitarbeiter.forEach(mt => {
          if(mt.data[index] == null){
            mt.data[index] = 0
          }
        })
      })
      //Kopiert Mitarbeiter um 2 Ansicht zu machen
      const Mitarbeiter2 = JSON.parse(JSON.stringify(Mitarbeiter));
      //Speichert die Daten für die Gesamt-Ansicht
      tz.forEach((tz,index) => {
        Mitarbeiter2.forEach(mt => {
          if(index != 0){
            mt.data[index] = mt.data[index-1] + mt.data[index]
          }
        })
      })

      //Daten für die Punkte jeden Themenzeitraum
      const chartdata = {
        labels: label,
        datasets: Mitarbeiter
      }
      this.chartdata1 = chartdata

      //Daten für die Gesamtansicht
      const chartdata2 = {
        labels: label,
        datasets: Mitarbeiter2
      }
      this.chartdata = chartdata2
      this.chartdata2 = chartdata2
      this.diagrammready = true

    },

    /*
      Filtert das zuvor erstellte Array von Daten zu den Punkten, mit den Filter von Themen und Zeitraume
    */
    calculateTableData(){
      const data = [];
      const map = new Map();
      this.data_user.forEach(item => {
        if((this.selectedJahr == null || this.selectedJahr == new Date(item.bewertungsdatum).getFullYear()) &&
          (this.selectedThemen.length == 0 || this.selectedThemen.includes(item.thema_id)) &&
          (this.selectedZeitraume.length == 0 || this.selectedZeitraume.includes(item.zeitraum_id))){
          const found = map.get(item.mitarbeiter_id);
          if(found == undefined){
            map.set(item.mitarbeiter_id, {
              punkte: item.punkte,
              name: item.mitarbeiter_name
            });
          }else{
            const newpunkte = item.punkte + found.punkte;
            const map_item = {
              punkte: newpunkte,
              name: item.mitarbeiter_name
            }
            map.set(item.mitarbeiter_id, map_item);
          }
        }
      });
      
      map.forEach(element => {
        data.push({
          name: element.name,
          punkte: element.punkte,
        });
      });
      data.sort((a, b) => b.punkte - a.punkte);
      this.daten = data;
    },
    /*

    */
    calculateZeitraumeMöglich(){
      const zeitraumeMöglich = [];
      this.zeitraume.forEach(zeitraum => {
        const year1 = Number(zeitraum.name.substring(0, 4))
        const year2 = Number(zeitraum.name.substring(13, 17))
        if(this.selectedJahr == null){
          zeitraumeMöglich.push(zeitraum)
        }
        else if(this.selectedJahr == year1 || this.selectedJahr == year2){
          zeitraumeMöglich.push(zeitraum)
        }
      });
      this.zeitraumeMöglich = zeitraumeMöglich
    }
  }
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
