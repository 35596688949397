<template>
  <ThemenUebersicht
    v-if="date"
    :current-date="date"
    :themen-colors="themenColors"
  />
</template>

<script>
import ThemenUebersicht from '../../components/Themen/ThemenUebersicht.vue'
export default {
  components: {
    ThemenUebersicht
  },

  data: () => ({
    themen: null,
    date: null,
    themenColors: {
      1: '#385F73',
      2: '#1F7087',
      3: '#42A5F5',
      4: '#EF5350',
      5: '#952175',
      6: '#26A69A',
      7: '#AED581'
    }
  }),

  mounted () {
    this.$store.commit('title', 'Themen')
    const current = new Date();
    let date = `${current.getFullYear()}`;
    if (current.getMonth() + 1 < 10)
      date = date + `-0${current.getMonth() + 1}`;
    else date = date + `-${current.getMonth() + 1}`;
    if (current.getDate() < 10) date = date + `-0${current.getDate()}`;
    else date = date + `-${current.getDate()}`;
    this.date = date;
  }

}
</script>
