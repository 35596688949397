<template>
  <div>
    <!-- Fehlermeldung -->
    <FehlerMeldung
      v-if="error"
      :error="error"
    />


    <v-card
      class="mx-auto my-12"
      max-width="800"
    >
      <v-card-title>
        Filter  
      </v-card-title>
      <v-card-text>
        <div v-if="ready">
          <!-- Filter Jahre -->
          <v-container fluid>
            <v-select
              v-model="selectedJahr"
              :items="jahre"
              label="Jahr"
              clearable
            >
            </v-select>
          </v-container>
        </div>
        <!-- Laden -->
        <div
          v-else
          class="text-center progress-circular-container"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
        </div>
      </v-card-text>
    </v-card>

    <!-- Übersicht in Tabellenform -->
    <UebersichtTabelle
      :themenzeitraume="themenzeitraume"
      :ready="ready"
      :daten="daten"
    />

    <!-- Übersicht in Diagrammform-->
    <DiagrammUebersicht
      :bewertungen="bewertungenGesucht"
      :ready="ready"
    />
  </div>
</template>

<script>
import api from "@/utils/api";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import UebersichtTabelle from "@/components/BewertungenUebersicht/BewertungenUebersichtTabelle.vue";
import DiagrammUebersicht from "@/components/BewertungenUebersicht/BewertungenUebersichtDiagramm.vue";

export default {
  components: {
    FehlerMeldung,
    DiagrammUebersicht,
    UebersichtTabelle
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data: () => ({
    bewertungen: [],
    bewertungenGesucht: [],
    anzahlthemen: null,
    error: null,
    daten: null,
    themenzeitraume: [],
    ready: false,
    selectedJahr: null,
    jahre: []

  }),
  watch: {
    selectedJahr(){
      if(this.ready == true){
        this.getData();
      }
    }
  },
  mounted() {
    this.$store.commit("title", "Übersicht");
    const url = `/api/bewertungen/alle`;
    api
      .get(url)
      .then(response => {
        this.bewertungen = response.bewertungen;
        const jahre =  [];
        this.bewertungen.forEach(bewertung => {
          const date = new Date(bewertung.bewertungsdatum);
          const jahr = date.getFullYear();
          if(!jahre.includes(jahr)){
            jahre.push(jahr);
          }
        });
        this.jahre = jahre  
        if(jahre.includes(new Date().getFullYear())){
          this.selectedJahr = new Date().getFullYear();
        }
        this.getData();
      })
      .catch(error => {
        this.error = error;
        this.ready = true;
      });
  },
  methods:{
    getData(){
      const themenzeitruam_ids = [];
      const data = [];
      const themenzeitraume = [];
      const bewertungenGesucht = [];
      this.bewertungen.forEach(bewertung => {
        if(this.selectedJahr == null || this.selectedJahr == new Date(bewertung.bewertungsdatum).getFullYear()){
          bewertungenGesucht.push(bewertung)
          if(!themenzeitruam_ids.includes(bewertung.themenzeitraum._id)){
            themenzeitraume.push(bewertung.themenzeitraum.createdAt);
            themenzeitruam_ids.push(bewertung.themenzeitraum._id);
            data.push({
              Themen: [],
              zeitraum: bewertung.themenzeitraum,
              anzahlbew: 1
            });
            const i = data.length - 1;
            data[i].Themen = [];
            data[i].Themen.push({
              Id: bewertung.thema_id,
              Anzahl: 1,
              Name: bewertung.thema.name
            });
          }else{
            const id = themenzeitruam_ids.indexOf(bewertung.themenzeitraum._id);
            data[id].anzahlbew++;
            const index = data[id].Themen.findIndex(item  => item.Id == bewertung.thema_id)
            if(index == -1){
              data[id].Themen.push({
                Id: bewertung.thema_id,
                Anzahl: 1,
                Name: bewertung.thema.name
              });
            }else{
              data[id].Themen[index].Anzahl++;
            }
          }
        }
      })
      this.bewertungenGesucht = bewertungenGesucht;
      this.daten = data;
      this.themenzeitraume = themenzeitraume;
      this.ready = true;
    }
  }  
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
  .row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
