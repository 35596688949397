<template>
  <div>
    <FehlerMeldung :error="error" />
    <v-card
      class="mx-auto my-12"
      max-width="800"
    >
      <span>
        <v-card-title>
          {{ zeitraum }}
        </v-card-title>
        <v-card-text v-if="ready">
          <v-simple-table>
            <template
              v-if="bewertungen && zeitraum && daten && anzahlmitarbeiter"
              #default
            >
              <thead>
                <tr>
                  <th class="text-left">
                    Thema
                  </th>
                  <th class="text-left">
                    Abgeschlossene Bewertungen
                  </th>
                  <th class="text-left">
                    Mitarbeiter
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(id, index) in ids"
                  :key="index"
                >
                  <td>{{ daten[id].name }}</td>
                  <td>{{ daten[id].Anzahl }}</td>
                  <td>{{ anzahlmitarbeiter }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <div
          v-else
          class="text-center progress-circular-container"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          />
      </div>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            :to="{ name: 'bewertungenuebersicht' }"
          >
            <v-icon large>
              mdi-arrow-left
            </v-icon>
            Zurück
          </v-btn>
        </v-card-actions>
      </span>
    </v-card>
    <DiagrammUebersicht
      :bewertungen="bewertungenzeitraum"
      :ready="ready"
    />
  </div>
</template>

<script>
import api from "@/utils/api";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import DiagrammUebersicht from "@/components/BewertungenUebersicht/BewertungenUebersichtDiagramm.vue";

export default {
  components: {
    FehlerMeldung,
    DiagrammUebersicht
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null
    }
  },

  data: () => ({
    bewertungen: [],
    bewertungenzeitraum: [],
    mitarbeiter: null,
    thema: null,
    error: null,
    daten: null,
    zeitraum: "Von:  Bis: ",
    anzahlmitarbeiter: null,
    ids: null,
    ready: false
  }),

  mounted() {
    this.$store.commit("title", "Übersicht");
    this.error = null;
    const url = `/api/bewertungen/alle`;
    api
      .get(url)
      .then(response => {
        this.bewertungen = response.bewertungen;
        const bew = response.bewertungen;
        const bewzeitruam=[]
        const data = [];
        const ids = [];
        bew.forEach(bewertung => {
          this.zeitraum =
            "Von: " +
            bewertung.themenzeitraum.beginn +
            " Bis: " +
            bewertung.themenzeitraum.ende;
          if (
            bewertung.themenzeitraum._id == this.$route.params.zeitraum
          ) {
            bewzeitruam.push(bewertung)
            if (!ids.includes(bewertung.thema_id)) {
              const mitarbeiter_anzahl = bewertung.themenzeitraum.mitarbeiter_anzahl
              if(mitarbeiter_anzahl == null){
                this.anzahlmitarbeiter = "nicht definiert"
              }else {
                this.anzahlmitarbeiter = mitarbeiter_anzahl
              }
              ids.push(bewertung.thema_id);
              data[bewertung.thema_id] = {};
              data[bewertung.thema_id].name = bewertung.thema.name;
              data[bewertung.thema_id].Anzahl = 1;
            } else {
              data[bewertung.thema_id].Anzahl++;
            }
          }
        });
        this.ids = ids;
        this.daten = data;
        this.bewertungenzeitraum = bewzeitruam
        this.ready = true
      })
      .catch(error => {
        this.error = error;
        this.ready = true;
      });
  }
};
</script>
  <style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
