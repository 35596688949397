import 'core-js/stable'

import Vue from 'vue'
import Vuex from 'vuex'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCompositionAPI from '@vue/composition-api'


import { UserVuetifyPreset } from 'vuetify'
// import de from 'vuetify/src/locale/de'
// import it from 'vuetify/src/locale/it'
// import en from 'vuetify/src/locale/en'
import 'vuetify/dist/vuetify.min.css'

Vue.config.performance = process.env.NODE_ENV !== 'production'
Vue.config.devtools = process.env.NODE_ENV !== 'production'

Vue.use(VueCompositionAPI)
Vue.use(Vuex)

const vuetifyOptions: UserVuetifyPreset = {
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    themes: {
      light: {
        anchor: 'rgba(0,0,0,.54)',
      },
    },
  },
}

Vue.config.productionTip = false

/** Vuetify wird für Vite-Compatibility im DevMode normal importiert, beim Build für Optimierung a-la-carte*/
import(process.env.NODE_ENV === 'production' ? 'vuetify/lib' : 'vuetify')
  .then(importedVuetify => {
    const Vuetify = importedVuetify.default
    Vue.use(Vuetify)
    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      vuetify: new Vuetify(),
      store,
      router,
      components: { App },
      mixins: [],
      render: h => h(App),
    })
  })
  .catch(err => {
    console.error(err)
  })