<template>
  <ThemaHinzufuegen
    v-if="ready && date"
    :current-date="date"
  />
</template>

<script>
import ThemaHinzufuegen from "../../components/Themen/ThemaHinzufuegen.vue";
export default {
  components: {
    ThemaHinzufuegen
  },

  data: () => ({
    ready: false,
    date: null
  }),

  mounted() {
    const current = new Date();
    let date = `${current.getFullYear()}`;
    if (current.getMonth() + 1 < 10)
      date = date + `-0${current.getMonth() + 1}`;
    else date = date + `-${current.getMonth() + 1}`;
    if (current.getDate() < 10) date = date + `-0${current.getDate()}`;
    else date = date + `-${current.getDate()}`;
    this.date = date;
    this.ready = true;
  }
};
</script>
