import Vue from 'vue'
import Router from 'vue-router';
import store from '@/store'

import BaseAppLayout from '@/views/BaseAppLayout.vue'

// JahresAbschluss
import Jahresabschlussbewertung from '@/views/JahresAbschluss/Jahresabschlussbewertung.vue'
import Jahresauswertung from '@/views/JahresAbschluss/Jahresauswertung.vue'


// History
import HistoryBewertungen from '@/views/History/HistoryBewertungen.vue'

// Zwischenstand
import UebersichtPunkte from '@/views/Zwischenstand/PunkteUebersicht.vue'


// BewertungenUebersicht
import BewertungenUebersicht from '@/views/BewertungenUebersicht/BewertungenUebersicht.vue'
import BewertungenGesamtUebersicht from '@/views/BewertungenUebersicht/BewertungenGesamtUebersicht.vue'

// Zeitraume
import ZeitraumBearbeiten from '@/views/Zeitraume/ZeitraumBearbeiten.vue'
import ThemenZeitraumBearbeiten from '@/views/Zeitraume/ThemenZeitraumBearbeiten.vue'

// Login
import Login from '@/views/Login/Login.vue'

// Verwalten
import AdminVerwalten from '@/views/Verwalten/AdminVerwalten.vue'
import BlacklistVerwalten from '@/views/Verwalten/BlacklistVerwalten.vue'
import VerwaltungListBearbeiten from '@/views/Verwalten/VerwaltungListBearbeiten.vue'

// Themen
import ThemenFragenUebersicht from '@/views/Themen/ThemenFragenUebersicht.vue'
import ThemenUebersicht from '@/views/Themen/ThemenUebersicht.vue'
import ThemenBearbeiten from '@/views/Themen/ThemenBearbeiten.vue'
import ThemaAbstimmung from '@/views/Themen/ThemaAbstimmung.vue'
import ThemaBearbeiten from '@/views/Themen/ThemaBearbeiten.vue'
import ThemaHinzufuegen from '@/views/Themen/ThemaHinzufuegen.vue'
import ThemaAbstimmungZusammenfassung from '@/views/Themen/ThemaAbstimmungZusammenfassung.vue'
import BewertungDelete from '@/views/Themen/BewertungDelete.vue'

// Backup
import BackupUebersicht from '@/views/Backup/BackupUebersicht.vue'
import BackupAnzeigen from '@/views/Backup/BackupAnzeigen.vue'

import api from '@/utils/api'
Vue.use(Router);

function updateFormProps(route) {
  return {
    id: parseInt(route.params.id)
  };
}

const routes = [
  {
    path: "/",
    name: "BaseAppLayout",
    redirect: "/login",
    component: BaseAppLayout,
    children: [
      {
        path: "/themen",
        name: "themen",
        meta: { requiresAuth: true },
        component: ThemenUebersicht
      },
      {
        path: "/themenbearbeiten",
        name: "themenbearbeiten",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresVerwaltung: true },
        component: ThemenBearbeiten
      },
      {
        path: "/zeitraum",
        name: "zeitraumbearbeiten",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresAdmin: true },
        component: ZeitraumBearbeiten
      }
      ,{
        path: "/themenzeitraum",
        name: "themenzeitraumbearbeiten",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresVerwaltung: true },
        component: ThemenZeitraumBearbeiten
      },
      {
        path: "/blacklist",
        name: "blacklistbearbeiten",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresAdmin: true },
        component: BlacklistVerwalten
      },
      {
        path: "/thema/hinzufuegen",
        name: "themahinzufuegen",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresVerwaltung: true },
        component: ThemaHinzufuegen
      },
      {
        path: "/thema/:id",
        name: "thema",
        props: updateFormProps,
        meta: { requiresAuth: true },
        component: ThemaAbstimmung
      },
      {
        path: "/thema/:id/zusammenfassung",
        name: "zusammenfassung",
        props: updateFormProps,
        meta: { requiresAuth: true },
        component: ThemaAbstimmungZusammenfassung
      },
      {
        path: "/thema/:id/bewertungen",
        name: "bewertungen",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresAdmin: true },
        component: BewertungenUebersicht
      },
      {
        path: "/thema/:id/bewertung/delete",
        name: "bewertungenDelete",
        props: updateFormProps,
        meta: { requiresAuth: true },
        component: BewertungDelete
      },
      {
        path: "/thema/:id/bearbeiten/",
        name: "themabearbeiten",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresVerwaltung: true },
        component: ThemaBearbeiten
      },
      {
        path: "/abschluss",
        name: "abschlussbewertung",
        props: updateFormProps,
        meta: { requiresAuth: true },
        component: Jahresabschlussbewertung
      },
      {
        path: "/auswertung",
        name: "abschlussbewertungauswertung",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresAdmin: true },
        component: Jahresauswertung
      },
      {
        path: "/admins",
        name: "adminsverwalten",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresSuperUser: true },
        component: AdminVerwalten
      },
      {
        path: "/bewertungen/:zeitraum",
        name: "bewertungenzuthema",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresAdmin: true },
        component: BewertungenUebersicht
      },
      {
        path: "/bewertungenuebersicht",
        name: "bewertungenuebersicht",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresAdmin: true },
        component: BewertungenGesamtUebersicht
      },
      {
        path: "/history",
        name: "historybewertungen",
        props: updateFormProps,
        meta: { requiresAuth: true},
        component: HistoryBewertungen
      },
      {
        path: "/themen_fragen",
        name: "themenfragenuebersicht",
        props: updateFormProps,
        meta: { requiresAuth: true},
        component: ThemenFragenUebersicht
      },
      {
        path: "/uebersichtpunkte",
        name: "uebersichtpunkte",
        props: updateFormProps,
        meta: { requiresAuth: true},
        component: UebersichtPunkte
      },
      {
        path: "/backups",
        name: "backups",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresVerwaltung: true },
        component: BackupUebersicht
      },
      {
        path: "/backups/:id",
        name: "backupzeitraum",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresVerwaltung: true },
        component: BackupAnzeigen
      },
      {
        path: "/verwaltung",
        name: "verwaltung",
        props: updateFormProps,
        meta: { requiresAuth: true, requiresAdmin: true },
        component: VerwaltungListBearbeiten
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false },
    component: Login
  }
];

//vl API abfrage?
function isAuthenticated() {
  if (localStorage.getItem("isAuthenticated") == "true") {
    return true;
  } else {
    return false;
  }
}
function isSuperUser() {
  if (store.getters.isSuperUser) {
    return true;
  } else {
    return false;
  }
}
function isAdmin_SuperUser() {
  if (store.getters.isAdmin || store.getters.isSuperUser) {
    return true;
  } else {
    return false;
  }
}
function isVerwalter_Admin_SuperUser() {
  if (store.getters.isSuperUser || store.getters.isAdmin || store.getters.isVerwaltung) {
    return true;
  } else {
    return false;
  }
}

function storeDataInStore(response){
  store.commit("isAdmin", response.isadmin);
  store.commit("isSuperUser", response.issuperuser);
  store.commit("isVerwaltung", response.isverwaltung);
  store.commit("isthemenzeitraum", response.isthemenzeitraum);
  store.commit("ispunktevergabe", response.ispunktevergabe);
  store.commit("ispunktevergabevorbei", response.ispunktevergabevorbei);
}

const router = new Router({
  mode: "hash",
  routes: routes
});
 
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next("/login");
    } else {
      if (to.matched.some(record => record.meta.requiresSuperUser)) {
        if (!isSuperUser()){
          api.get("/api/info/rechtegruppe").then(response => {
            if(response.issuperuser){
              storeDataInStore(response);
              next()
            }else{
              next("/login");
            }
          }).catch(error => {
            console.log(error)
            next("/login");
          })
        } 
        else{
          next();
        } 
      } else if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (!isAdmin_SuperUser()) {
          api.get("/api/info/rechtegruppe").then(response => {
            if(response.isadmin || response.issuperuser){
              storeDataInStore(response);
              next()
            }else{
              next("/login");
            }
          }).catch(error => {
            console.log(error)
            next("/login");
          })
        }
        else {
          next();
        }
      } else if (to.matched.some(record => record.meta.requiresVerwaltung)){
        if(!isVerwalter_Admin_SuperUser()){
          api.get("/api/info/rechtegruppe").then(response => {
            if(response.isadmin || response.issuperuser || response.isverwaltung){
              storeDataInStore(response);
              next()
            }else{
              next("/login");
            }
          }).catch(error => {
            console.log(error)
            next("/login");
          })
        }else{
          next();
        }
      }else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
