<template>
  <HistoryBewertung 
    :ready="ready"
    :themen="themen"
    :zeitraume="zeitraume"
    :bewertungen="bewertungen"
    :data="data"
  />
</template>

<script>
import api from "@/utils/api";
import HistoryBewertung from "@/components/History/HistoryBewertungen.vue";

export default {
  components: {
    HistoryBewertung

  },

  data: () => ({
    ready: false,
    zeitraume: [],
    themen: [],
    bewertungen: [],
    data: []
  }),
  computed: {},
  mounted() {
    this.$store.commit("title", "Historie");
    const url = `/api/bewertungen/uebersicht`;
    let id = 0;
    api
      .get(url)
      .then(response => {
        this.bewertungen = response.bewertungen;
        const zeitraume = []
        const themen = []
        this.bewertungen.forEach(bew => {
          const zeitraum = "Von: " + bew.themenzeitraum.beginn + " Bis: " + bew.themenzeitraum.ende

          const found = zeitraume.find(zr => zr == zeitraum)
          if(!found){
            zeitraume.push(zeitraum)
          }

          const foundthema = themen.find(th => th ==  bew.thema_id)
          if(!foundthema){
            themen.push({
              id: bew.thema_id,
              name: bew.thema.name
            })
          }

          this.data.push({
            zeitraum: zeitraum,
            bewertung: bew,
            id: id
          });
          id++;
        });
        this.zeitraume = zeitraume
        this.themen = themen
        this.ready = true;
      })
      .catch(error => {
        this.error = error;
        this.ready = true;
      });
  }
};
</script>

