import axios from 'axios'
import router from '../router'

export default {
  setDefaultHeaders () {
    const token = localStorage.getItem('token')
    axios.defaults.headers.common['Authorization'] = `Token: ${token}`
    // axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_URL}${process.env.VUE_APP_BACKEND_API}`
    const UNAUTHORIZED = 401
    axios.interceptors.response.use(
      response => response,
      error => {
        //console.log(error)
        if (error && Object.keys(error).includes('response')) {
          if (error.response) {
            const { status } = error.response
            if (status === UNAUTHORIZED) {
              if (router.currentRoute.name !== 'login') {
                // sessionStorage.setItem('redirect', router.currentRoute.path)
                window.location.href = `/`
              }
            }
          }
        }
        return Promise.reject(error)
      }
    )
  },
  checkExpireRefresh () {
    const d = new Date()
    const currDate = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
    const programDate = localStorage.getItem('programDate')
    if (programDate !== currDate) {
      console.info('Force Reload')
      window.location.reload(true)
    }
  },
  get (url, data) {
    this.setDefaultHeaders()
    this.checkExpireRefresh()
    return axios.get(url, data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  },
  post (url, data) {
    this.setDefaultHeaders()
    return axios.post(url, data, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  },
  del (url, data) {
    this.setDefaultHeaders()
    return axios.delete(url, data)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  },
  put (url, data) {
    this.setDefaultHeaders()
    return axios.put(url, data, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error))
  }
}
