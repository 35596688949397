<template>
  <div>
    <!-- Fehlermeldung -->
    <FehlerMeldung
      :error="error"
      :v-if="error"
    />
    <v-card
      class="mx-auto my-12"
      max-width="800"
    >
      <v-card-title>Themen-Zeitraum bearbeiten</v-card-title>
      <div v-if="gespeichert">
        <v-card-text>
          Themen-Zeitraum hinzugefügt!
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn
            text
            color="primary"
            @click="gespeichert = false"
          >
            Weiter
            <v-icon large>
              mdi-send
            </v-icon>
          </v-btn>
        </v-card-actions>
      </div>
      <div v-else>
        <v-card-text v-if="themenzeitraumlist">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
          <!-- Tabelle -->
          <v-data-table
            :search="search"
            :headers="headers"
            :items="getData"
            :items-per-page="10"
            class="elevation-1"
            :loading="getloading"
          >
            <template #[`item.mitarbeiter_anzahl`]="{ item }">
              <span v-if="item.mitarbeiter_anzahl">
                {{ item.mitarbeiter_anzahl }}
              </span>
              <span v-else>
                0
              </span>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                icon
                @click="openEditDialog(item)"
              >
                <v-icon small>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                icon
                @click="openDeleteDialog(item)"
              >
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn>
              <DialogBestaetigen
                :id="delid"
                :visible="dialogVisible"
                titel="Themenzeitraum"
                :frage="delfrage"
                @bestaetigen="deleteEintrag"
                @close="dialogVisible = false"
              />
            </template>
          </v-data-table>

          <!-- Buttons / Dialoge-->
          <v-card-actions v-if="!hideinput">
            <v-spacer />
            <v-btn
              :loading="isSaving"
              text
              color="primary"
              style="margin:auto"
              @click="openEditDialog(null)"
            >
              Hinzufügen
              <v-icon large>
                mdi-send
              </v-icon>
            </v-btn>
            <DialogZeitraumHinzufeugen
              :visible="dialogVisibleZR"
              :item="edititem"
              :list="themenzeitraumlist"
              :themen="themen_data"
              @close="closeDialogHinzufuegen"
              @update="save"
              @new="erstellen"
              @del="deleteEintrag"
            />
          </v-card-actions>
        </v-card-text>

        <!-- Loading -->
        <v-card-text v-else>
          <div class="text-center progress-circular-container">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
          </div>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>
<script>
import DialogZeitraumHinzufeugen from "@/components/Dialoge/DialogZeitraumHinzufeugen.vue";
import DialogBestaetigen from "@/components/Dialoge/DialogBestaetigen.vue";
import FehlerMeldung from "@/components/Meldungen/FehlerMeldung.vue";
import api from "@/utils/api";
export default {
  components: {
    DialogZeitraumHinzufeugen,
    DialogBestaetigen,
    FehlerMeldung
  },
  data() {
    return {
      themenzeitraumlist: null,
      themen_data: [],
      search: "",
      isSaving: false,
      error: null,
      hideinput: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Beginn", value: "beginn" },
        { text: "Ende", value: "ende" },
        { text: "Anzahl Themen", value: "themen.length"},
        { text: "Anzahl Mitarbeiter", value: "mitarbeiter_anzahl" },
        { text: "Aktionen", value: "actions", sortable: false }
      ],
      //loeschen
      delfrage: "",
      delid: -1,
      dialogVisible: false,
      //edit
      edititem: {},
      dialogVisibleZR: false,
      loading: true,
      gespeichert: false
    };
  },

  computed: {
    getData() {
      return this.themenzeitraumlist;
    },
    submitDisabled() {
      if (this.selected) return false;
      else return true;
    },
    adminlistids() {
      const ret = [];
      this.themenzeitraumlist.forEach(eintrag => {
        ret.push(eintrag.id);
      });
      return ret;
    },
    getloading() {
      return this.loading;
    }
  },  mounted() {
    this.$store.commit("title", "Themen-Zeitraum bearbeiten");
    this.updateList();
    this.updateThemen();
  },
  methods: {
    getcurrentDate() {
      const current = new Date();
      let date = `${current.getFullYear()}`;
      if (current.getMonth() + 1 < 10)
        date = date + `-0${current.getMonth() + 1}`;
      else date = date + `-${current.getMonth() + 1}`;
      if (current.getDate() < 10) date = date + `-0${current.getDate()}`;
      else date = date + `-${current.getDate()}`;
      return date;
    },
    save(id, beginn, ende, mitarbeiter_anzahl, themen) {
      this.dialogVisibleZR = false;
      if ((id && beginn && ende) || (id == 0 && beginn && ende)) {
        this.error = null;
        const url = `api/themenzeitraum/${id}`;
        const zeitraum = {
          beginn: beginn,
          ende: ende,
          mitarbeiter_anzahl: mitarbeiter_anzahl,
          themen: themen
        };
        const postData = {
          zeitraum: zeitraum
        };
        api
          .put(url, postData)
          .then(() => {
            this.updateList();
            const currentDate = this.getcurrentDate();
            if (beginn <= currentDate && ende >= currentDate) {
              this.$store.commit("isthemenzeitraum", true);
            } else {
              this.$store.commit("isthemenzeitraum", false);
            }
          })
          .catch(error => {
            this.error = error;
          });
      }
    },
    /*
    erstellelt neuen Themenzeitraum mit den übergebenen Daten
    */
    erstellen(beginn, ende, themen) {
      this.dialogVisibleZR = false;
      this.error = null;
      const url = "api/themenzeitraum";
      const postData = {
        beginn: beginn,
        ende: ende,
        themen: themen
      };
      api
        .post(url, postData)
        .then(() => {
          this.updateList();
          const currentDate = this.getcurrentDate();
          if (beginn <= currentDate && ende >= currentDate) {
            this.$store.commit("isthemenzeitraum", true);
          } else {
            this.$store.commit("isthemenzeitraum", false);
          }
        })
        .catch(error => {
          this.error = error;
        });
    },
    openEditDialog(item) {
      this.edititem = item;
      this.dialogVisibleZR = true;
    },
    openDeleteDialog(item) {
      this.delfrage = "Themen-Zeitraum löschen ?\nvon: "  + item.beginn +"\nbis: "+item.ende;
      this.delid = Number(item.id);
      this.dialogVisible = true;
    },
    deleteEintrag(id) {
      this.dialogVisible = false;
      this.error = null;
      if (id || id == 0) {
        const url = `/api/themenzeitraum/${id}`;
        api
          .del(url)
          .then(() => {
            const currentDate = this.getcurrentDate();
            const tz = this.themenzeitraumlist.find(zt => (zt.id == id));
            if (tz.beginn <= currentDate && tz.ende >= currentDate) {
              this.$store.commit("isthemenzeitraum", false);
            }
            this.updateList();
          })
          .catch(error => {
            this.error = error;
          });
      }
    },

    /*
    aktualisiert die themenzeitraume 
    */
    updateList() {
      const url2 = `/api/themenzeitraum`;
      this.loading = true;
      this.hideinput = false;
      api
        .get(url2)
        .then(response => {
          this.themenzeitraumlist = response.themenzeitraum;
          this.loading = false;
        })
        .catch(error => {
          if (this.themenzeitraumlist == null) {
            this.themenzeitraumlist = [];
            this.hideinput = true;
          }
          this.loading = false;
          this.error = error;
        });
    },
    //aktualisiert die Liste der Themen
    updateThemen() {
      //holt alle Themen die freigeben  sind
      const url_freigegeben = `/api/themen/freigegeben`;
      api
        .get(url_freigegeben)
        .then(response => {
          const themen_table = [];
          response.themen.forEach(thema => {
            themen_table.push({
              id: thema.id,
              thema: thema.thema,
              anzahlfragen: thema.fragen.length
            });
          });
          this.themen_data = themen_table;
        })
        .catch(error => {
          this.error = error;
          if (this.themen_data == null) {
            this.themen_data = [];
          }
        });
    },
    closeDialogHinzufuegen(){
      this.dialogVisibleZR = false;
    }
  }
};
</script>
<style scoped>
  .progress-circular-container{
    padding-top: 50px;
    padding-bottom: 50px
  }
</style>
